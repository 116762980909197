/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ 
                description,
                lang,
                meta,
                title,
                jobTitle,
                jobDescription,
                jobDatePosted,
                jobValidThrough,
                jobIdentifier,
                jobEmploymentType,
                companyName,
                companyWebsite,
                companyLogoDestination,
                companyStreet,
                companyCity,
                companyState,
                companyPostalCode }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={(title && title.length > 0 ? title + ' | ' : '') + 'JUG Saxony'}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'org:site_name',
          content: 'JUG Saxony',
        },
        {
          property: 'org:locale',
          content: 'de_DE',
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {jobTitle && jobDescription && jobDatePosted && jobValidThrough && jobIdentifier &&
      <script type="application/ld+json">{
        JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'JobPosting',
          'title': jobTitle,
          'description': '<p>' + jobDescription + '</p>',
          'identifier': {
            '@type': 'PropertyValue',
            'name': companyName,
            'value': jobIdentifier,
          },
          'datePosted': jobDatePosted,
          'validThrough': jobValidThrough,
          'employmentType': jobEmploymentType,
          'hiringOrganization': {
            '@type': 'Organization',
            'name': companyName,
            'sameAs': companyWebsite,
            'logo': companyLogoDestination,
          },
          'jobLocation': {
            '@type': 'Place',
            'address': {
              '@type': 'PostalAddress',
              'streetAddress': companyStreet,
              'addressLocality': companyCity,
              'addressRegion': companyState,
              'postalCode': companyPostalCode,
              'addressCountry': 'DE',
            },
          }
        })
      }</script>
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
